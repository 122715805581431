<template>
  <div class="content_wrapper">
    <div class="page_title">
      <i class="tf-icons ti ti-settings ti_box"></i>
      <h4 class="title">연동관리</h4>
    </div>
    <div class="tabMenu_inner border-0 mt-0">
        <ul class="tab_link_box ctgy02">
          <li :class="{ active: exchange === 0 }" @click="changeTab(0)">ALL</li>
          <li :class="{ active: exchange === 1 }" @click="changeTab(1)">OKX</li>
          <li :class="{ active: exchange === 3 }" @click="changeTab(3)">
            Toobit
          </li>
          <li :class="{ active: exchange === 2 }" @click="changeTab(2)">
            BingX
          </li>
          <li :class="{ active: exchange === 4 }" @click="changeTab(4)">
            DeepCoin
          </li>
        </ul>
      </div>
    <div class="col-12 pl-10 pr-10 mb-20">
      <div class="total_info">
        <div @click="changeState(0)" :class="{ active: status === 0 }">
          <p class="fs-18">총 연동 등록수</p>
          <p class="text_blue fs-25">
            {{ connectCountInfo.sum?.toLocaleString() }}
          </p>
        </div>
        <div @click="changeState(2)" :class="{ active: status === 2 }">
          <p class="fs-18">연동</p>
          <p class="text_plus fs-25">
            {{ connectCountInfo.operation?.toLocaleString() }}
          </p>
        </div>
        <div @click="changeState(1)" :class="{ active: status === 1 }">
          <p class="fs-18">미연동</p>
          <p class="text_minus fs-25">
            {{ connectCountInfo.notlinked?.toLocaleString() }}
          </p>
        </div>
      </div>
    </div>
    <div class="col-12 pl-10 pr-10 mb-20">
      <div class="card">
        <div class="card_header">
          <h5 class="card_title">
            <i class="ti ti-search mr-5"></i>사용자 검색
          </h5>
        </div>
        <!---->
        <div class="form_inner">
          <div class="col-12 mb-15 search_group">
            <h5 class="item_name">RETRI_IDX</h5>
            <input
              type="number"
              v-model="info.retri_id"
              class="form_control display-inline wp-36"
              placeholder="Search"
              @keyup.enter="infoSearch"
            />
          </div>
          <div class="col-12 mb-15 search_group">
            <h5 class="item_name">UID</h5>
            <input
              type="text"
              v-model="info.uid"
              class="form_control display-inline wp-36"
              placeholder="UID search"
              @keyup.enter="infoSearch"
            />
          </div>
          <div class="col-12 mb-15 search_group">
            <h5 class="item_name">연동 상태</h5>
            <div class="form-check form-check-inline">
              <input
                type="checkbox"
                id="Checkbox1"
                value="1"
                v-model="connectWating"
                @change="checkType"
              />
              <label for="Checkbox1">승인 대기중</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                type="checkbox"
                id="Checkbox3"
                value="2"
                v-model="connectSuccess"
                @change="checkType"
              />
              <label for="Checkbox3">승인 완료</label>
            </div>
          </div>
          <div class="col-12 mb-15 search_group">
            <h5 class="item_name">거래소</h5>
            <select
              class="form-select display-inline wp-15 mr-5"
              data-allow-clear="true"
              v-model="info.exchange"
            >
              <option value="">All</option>
              <option value="1">OKX</option>
              <option value="3">Toobit</option>
              <option value="2">BingX</option>
              <option value="4">DeepCoin</option>
            </select>
          </div>
          <div class="col-12 mb-15 search_group">
            <h5 class="item_name">일자</h5>
            <input
              type="date"
              class="form_control wp-20 mr-5 display-inline"
              v-model="info.from_data"
            />
            ~
            <input
              type="date"
              class="form_control wp-20 ml-5 display-inline"
              v-model="info.to_data"
            />
          </div>
          <div class="filter-btn">
            <button
              type="button"
              class="btn btn-primary mlr-5"
              @click="infoSearch"
            >
              검색
            </button>
            <button
              @click="infoClear"
              type="button"
              class="btn btn-secondary mlr-5"
            >
              클리어
            </button>
          </div>
        </div>
        <!---->
      </div>
    </div>
    <!--  -->
    <div class="col-12 pl-10 pr-10 mb-20">
      <div class="card">
        <div class="table-responsive text-nowrap dataTables_wrapper">
          <table class="table">
            <thead class="table-light">
              <tr>
                <th>RETRI_IDX</th>
                <th>거래소</th>
                <th>UID</th>
                <th>연동 상태</th>
                <th>연동 시간</th>
                <th>정산 비율</th>
                <!-- <th>비고</th> -->
              </tr>
            </thead>
            <tbody v-if="!isLoading">
              <tr v-if="connectList.length == 0">
                <td colspan="7">데이터 내역이 없습니다.</td>
              </tr>
              <tr v-for="(data, i) in connectList" :key="i">
                <!-- retriId -->
                <td>{{ data.retri_id }}</td>
                <td>
                  {{ exchangeNaming(data.exchange) }}
                </td>
                <td>{{ data.uid }}</td>
                <td>{{ data.connet == 1 ? "승인 대기중" : (data.connet == 2 ? "승인 완료" : "승인 실패") }}</td>
                <td>{{ data.datetime.slice(0, 16) }}</td>
                <td>{{ data.ratio }}</td>
                <!-- <td>
                  <button @click="deleteUid(data.retri_id,data.uid)" class="btn_gray_bg" >
                      <i class="ti ti-trash me-1"></i> Delete
                  </button>
                </td> -->
              </tr>
            </tbody>
            <tbody v-if="isLoading">
              <td colspan="8" class="list_loading">
                <div class="loading_spinner">
                  <div class="loading_circle"></div>
                </div>
              </td>
            </tbody>
          </table>
          <ThePaging
            v-if="totalPages > 1"
            :page="page"
            :changePage="changePage"
            :totalPages="totalPages"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- <ModalMsg
    v-if="modalState"
    :msg="fetchStatus == 200 ? '성공적으로 삭제하였습니다.' : '실패하였습니다. 다시 시도해주세요.'"
  /> -->
</template>
<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import ThePaging from "@/components/public/ThePaging.vue";
// import ModalMsg from "@/components/modal/ModalMsg.vue";
const store = useStore();
const connectList = computed(() => {
  return store.state.connect.connectList;
});
const totalPages = computed(() => {
  return store.state.connect.totalPages;
});
const connectCountInfo = computed(() => {
  return store.state.connect.connectCountInfo;
});
const page = computed(() => {
  return store.state.connect.page;
});
const isLoading = computed(() => {
  return store.state.connect.isLoading;
});
// const modalState = computed(() => {
//   return store.state.admin.modalState;
// });
// const fetchStatus = computed(() => {
//   return store.state.admin.fetchStatus;
// });
const exchange = ref(0);
const status = ref(0);
let info = {
  status: "",
  uid:"",
  from_data: "",
  to_data: "",
  retri_id: "",
  exchange: "",
};
const connectWating = ref(false);
const connectSuccess = ref(false);
const infoSearch = async () => {
  if (
    (connectWating.value && connectSuccess.value) ||
    (!connectWating.value && !connectSuccess.value)
  )
    info.status = "";
  else if (connectWating.value) info.status = "1";
  else info.status = "2";
  let reqInfo = {
    status: info.status ?? "",
    uid : info.uid ?? "",
    from_data: info.from_data ?? "",
    to_data: info.to_data ?? "",
    retri_id: info.retri_id ?? "",
    exchange: info.exchange ?? "",
  };
  fetchFn(reqInfo);
};
const infoClear = async () => {
  info = {
    status: "",
    uid : "",
    from_data: "",
    to_data: "",
    retri_id: "",
    exchange: "",
  };
  fetchFn(info);
};
const exchangeNaming = (flag) => {
  let exchange;
  switch (flag) {
    case "1":
      exchange = "OKX";
      break;
    case "2":
      exchange = "BingX";
      break;
    case "3":
      exchange = "Toobit";
      break;
    case "4":
      exchange = "DeepCoin";
      break;
  }
  return exchange;
};
const changePage = async (str) => {
  store.commit("connect/setPage", str);
  await store.dispatch("connect/getConnect");
};
const changeTab = async (num) => {
  exchange.value = num;
  status.value = 0;
  let reqInfo = {
    status: "",
    uid: "",
    from_data: "",
    to_data: "",
    retri_id: "",
    exchange: exchange.value == 0 ? "" : exchange.value,
  };
  store.commit("connect/setPage", 1);
  store.commit("connect/setConnectInfo", reqInfo);
  if (exchange.value == 0) {
    await store.dispatch("connect/getConnectCount");
  } else {
    await store.dispatch("connect/getExcConnectCount", exchange.value);
  }
  await store.dispatch("connect/getConnect");
};
const changeState = async (num) => {
  status.value = num;
  store.commit("connect/setPage", 1);
  let reqInfo = {
    status: status.value == 0 ? "" : status.value,
    exchange: exchange.value == 0 ? "" : exchange.value,
    uid:"",
    from_data: "",
    to_data: "",
    retri_id: "",
  };
  store.commit("connect/setConnectInfo", reqInfo);
  await store.dispatch("connect/getConnect");
};
// const deleteUid = async (retri_id,uid) => {
// console.log(retri_id,uid);
// store.commit("admin/changeModalState", true);
// }
const fetchFn = async (info) => {
  store.commit("connect/setPage", 1);
  store.commit("connect/setConnectInfo", info);
  await store.dispatch("connect/getConnect");
};
const createFn = async () => {
  await store.dispatch("connect/getConnectCount");
  fetchFn(info);
};
createFn();
</script>
