<template>
  <div class="tabMenu_inner border-0 mt-0">
    <ul class="tab_link_box ctgy02">
      <li :class="{ active: exchange === 0 }" @click="changeTab(0)">ALL</li>
      <li :class="{ active: exchange === 1 }" @click="changeTab(1)">OKX</li>
      <li :class="{ active: exchange === 3 }" @click="changeTab(3)">Toobit</li>
      <li :class="{ active: exchange === 2 }" @click="changeTab(2)">BingX</li>
      <li :class="{ active: exchange === 4 }" @click="changeTab(4)">
        DeepCoin
      </li>
    </ul>
  </div>
  <div class="card mb-20">
    <div class="card_header">
      <h5 class="card_title"><i class="ti ti-files mr-5"></i>페이백 내역</h5>
    </div>
    <div class="form_inner">
      <div class="col-12 mb-15 search_group">
        <h5 class="item_name">RETRI_IDX</h5>
        <input
          type="number"
          v-model="info.retri_id"
          class="form_control display-inline wp-36"
          placeholder="Search"
          @keyup.enter="infoSearch()"
        />
      </div>
      <div class="col-12 mb-15 search_group">
            <h5 class="item_name">UID</h5>
            <input
              type="text"
              v-model="info.uid"
              class="form_control display-inline wp-36"
              placeholder="UID search"
              @keyup.enter="infoSearch"
            />
          </div>
      <div class="col-12 mb-15 search_group">
        <h5 class="item_name">거래소</h5>
        <select
          class="form-select display-inline wp-15 mr-5"
          data-allow-clear="true"
          v-model="info.exchange"
        >
          <option value="0">All</option>
          <option value="1">OKX</option>
          <option value="3">Toobit</option>
          <option value="2">BingX</option>
          <option value="4">DeepCoin</option>
        </select>
      </div>
      <div class="col-12 mb-15 search_group">
        <h5 class="item_name">일자</h5>
        <input
          type="date"
          class="form_control wp-20 mr-5 display-inline"
          v-model="info.start_date"
        />
        ~
        <input
          type="date"
          class="form_control wp-20 ml-5 display-inline"
          v-model="info.end_date"
        />
      </div>
      <div class="filter-btn">
        <button type="button" class="btn btn-primary mlr-5" @click="infoSearch">
          검색
        </button>
        <button
          @click="infoClear"
          type="button"
          class="btn btn-secondary mlr-5"
        >
          클리어
        </button>
      </div>
    </div>
  </div>
  <div class="col-12 id_totalinfo mb-20" v-if="info.retri_id && referralInfo?.retri_id">
  
      <h3>RETRI ID {{ referralInfo.retri_id }} 의 거래소별 전체 수익금</h3>
      <ul class="totalinfo_head">
        <li>전체</li>
        <li>OKX</li>
        <li>Toobit</li>
        <li>BingX</li>
        <li>DeepCoin</li>
      </ul>
      <ul class="totalinfo_list">
        <li>{{formatNum(referralInfo.total_sum,4)}}</li>
        <li>{{formatNum(referralInfo.total_okx,4)}}</li>
        <li>{{formatNum(referralInfo.total_toobit,4)}}</li>
        <li>{{formatNum(referralInfo.total_bingx,4)}}</li>
        <li>{{formatNum(referralInfo.total_deepcoin,4)}}</li>
      </ul>
  </div>
  <!--  -->
  <div class="col-12 mb-20">
    <div class="card">
      <div class="table-responsive text-nowrap dataTables_wrapper">
        <table class="table">
          <thead class="table-light">
            <tr>
              <th>RETRI_IDX</th>
              <th>USER_ID</th>
              <th>거래소</th>
              <th>UID</th>
              <th>날짜</th>
              <th>지급액</th>
              <th>지급일자</th>
              <th>지급내역</th>
              <th>비고</th>
            </tr>
          </thead>
          <tbody v-if="!isLoading">
            <tr v-if="paybackList.length == 0">
              <td colspan="7">데이터 내역이 없습니다.</td>
            </tr>
            <tr v-for="(data, i) in paybackList" :key="i">
              <td>{{ data.retri_id }}</td>
              <td>{{ data.user_id }}</td>
              <td>{{ data.exchange }}</td>
              <td>{{ data.uid }}</td>
              <td>{{ data.datetime }}</td>
              <td>{{ formatNum(data.payment,4) }}</td>
              <td>{{ data.paymentdate }}</td>
              <td>{{ data.paymentdetails }}</td>
              <td>{{ data.status }}</td>
            </tr>
          </tbody>
          <tbody v-if="isLoading">
            <td colspan="8" class="list_loading">
              <div class="loading_spinner">
                <div class="loading_circle"></div>
              </div>
            </td>
          </tbody>
        </table>
        <ThePaging
          v-if="totalPages > 1"
          :page="page"
          :changePage="changePage"
          :totalPages="totalPages"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import ThePaging from "@/components/public/ThePaging.vue";
import { formatNum } from "@/utils/common";
const store = useStore();
const paybackList = computed(() => {
  return store.state.referral.paybackList;
});
const referralInfo = computed(() => {
  return store.state.referral.referralInfo;
});
const page = computed(() => {
  return store.state.referral.page;
});
const totalPages = computed(() => {
  return store.state.referral.totalPages;
});
const isLoading = computed(() => {
  return store.state.referral.isLoading;
});
let exchange = ref(0);
let info = {
  exchange: 0,
  uid : "",
  retri_id: "",
  start_date: "",
  end_date: "",
};
const infoSearch = async () => {
  let reqInfo = {
    exchange: info.exchange ?? 0,
    uid : info.uid ?? "",
    retri_id: info.retri_id ?? "",
    start_date: info.start_date ?? "",
    end_date: info.end_date ?? "",
  };
  if(info.retri_id){
    await  store.dispatch("referral/getExchangeTotal",info.retri_id);
  }
  store.commit("referral/setPage", 1);
  store.commit("referral/setPaybackInfo", reqInfo);
  await store.dispatch("referral/getPaybackLog");
};
const infoClear = async () => {
  info = {
    exchange: 0,
    uid : "",
    retri_id: "",
    start_date: "",
    end_date: "",
  };
  store.commit("referral/setPaybackInfo", info);
  store.commit("referral/setPage", 1);
  await store.dispatch("referral/getPaybackLog");
};
const changePage = async (str) => {
  store.commit("referral/setPage", str);
  await store.dispatch("referral/getPaybackLog");
};
const changeTab = async (state) => {
  exchange.value = state;
  let reqInfo = {
    exchange: exchange.value ?? 0,
    uid: "",
    retri_id: "",
    start_date: "",
    end_date: "",
  };
  store.commit("referral/setPage", 1);
  store.commit("referral/setPaybackInfo", reqInfo);
  await store.dispatch("referral/getPaybackLog");
};
const createFn = async () => {
  let reqInfo = {
    exchange: 0,
    uid:"",
    retri_id: "",
    start_date: "",
    end_date: "",
  };
  store.commit("referral/setPage", 1);
  store.commit("referral/setPaybackInfo", reqInfo);
  await store.dispatch("referral/getPaybackLog");
};
createFn();
</script>
