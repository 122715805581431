import api from "@/api/apiConnect";

export default {
  namespaced: true,
  state() {
    return {
      reqConnectInfo: {},
      page: 1,
      isLoading: false,
      //res
      connectList: [],
      totalPages: 0,
      connectCountInfo: {},
      // fetchStatus : "",
    };
  },
  mutations: {
    setConnectInfo(state, payload) {
      state.reqConnectInfo = {
        size: 10,
        uid: payload.uid,
        status: payload.status,
        from_data: payload.from_data,
        to_data: payload.to_data,
        retri_id: payload.retri_id,
        exchange: payload.exchange,
      };
    },
    setPage(state, str) {
      if (str == "prev") {
        state.page--;
      } else if (str == "next") {
        state.page++;
      } else {
        state.page = 1;
      }
    },
  },
  actions: {
    async getConnect(context) {
      try {
        context.state.totalPages = 1;
        context.state.isLoading = true;
        let response = await api.getConnectApi(
          context.state.reqConnectInfo,
          context.state.page
        );
        context.state.connectList = response.results;
        context.state.totalPages = response.totalPages;
        context.state.isLoading = false;
      } catch (error) {
        return;
      }
    },
    async getConnectCount(context) {
      try {
        let response = await api.getConnectCountApi();
        context.state.connectCountInfo = response.results[0];
      } catch (error) {
        return;
      }
    },
    async getExcConnectCount(context, exchange) {
      try {
        let response = await api.getExcConnectCountApi(exchange);
        context.state.connectCountInfo = response.results[0];
      } catch (error) {
        return;
      }
    },
  },
};
