<template>
  <div class="modal_overlay" @click.self="closeModal">
    <div class="modal_pop_small">
      <div class="smallPop popup_block_large">
        <div class="pop_title mt-20">
          <p v-if="props.status == 3">거래소별 정산 후 수익금 현황</p>
          <p v-else>거래소별 정산 전 수익금 현황</p>
        </div>
        <div class="con_wrap mt-20">
          <div class="table-responsive text-nowrap dataTables_wrapper">
            <table class="table">
              <thead class="table-light">
                <tr>
                  <th>거래소</th>
                  <th>금일 수익금</th>
                  <th>금주 수익금</th>
                  <th>금월 수익금</th>
                  <th>총 수익금</th>
                </tr>
              </thead>
              <tbody v-if="props.status == 3">
                <tr v-for="(data, i) in exchangeArr" :key="i">
                  <td>{{ data.name }}</td>
                  <td>{{ numComma(data.daily_profit, 4) }}$</td>
                  <td>{{ numComma(data.weekly_profit, 4) }}$</td>
                  <td>{{ numComma(data.monthly_profit, 4) }}$</td>
                  <td>{{ numComma(data.total_profit, 4) }}$</td>
                </tr>
                <tr class="bg_gray">
                  <td>전체</td>
                  <td>{{ formatNum(dashboardInfo.day_profit, 4) }}$</td>
                  <td>{{ formatNum(dashboardInfo.week_profit, 4) }}$</td>
                  <td>{{ formatNum(dashboardInfo.month_profit, 4) }}$</td>
                  <td>{{ formatNum(dashboardInfo.total_profit, 4) }}$</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr v-for="(data, i) in exchangeArr" :key="i">
                  <td>{{ data.name }}</td>
                  <td>{{ numComma(data.before_daily_profit, 4) }}$</td>
                  <td>{{ numComma(data.before_weekly_profit, 4) }}$</td>
                  <td>{{ numComma(data.before_monthly_profit, 4) }}$</td>
                  <td>{{ numComma(data.before_total_profit, 4) }}$</td>
                </tr>
                <tr class="bg_gray">
                  <td>전체</td>
                  <td>{{ formatNum(props.dashboardInfo.day_befor_profit, 4) }}$</td>
                  <td>{{ formatNum(props.dashboardInfo.week_befor_profit, 4) }}$</td>
                  <td>{{ formatNum(props.dashboardInfo.month_befor_profit, 4) }}$</td>
                  <td>{{ formatNum(props.dashboardInfo.total_befor_profit, 4) }}$</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="btn_bottom pt-0 pb-50">
          <button class="btn_gray" @click="closeModal">확인</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
// import { computed, ref } from "vue";
import { formatNum, numComma } from "@/utils/common";
const store = useStore();
const props = defineProps({
  status: { type: Number },
  dashboardInfo: { type: Object },
  exchangeArr: { type: Array },
});
// const dashboardInfo = computed(() => {
//   return store.state.manage.dashboardInfo;
// });
// const monthCountInfo = computed(() => {
//   return store.state.closing.monthCountInfo;
// });
// const weekCountInfo = computed(() => {
//   return store.state.closing.weekCountInfo;
// });
// const dailyCountInfo = computed(() => {
//   return store.state.closing.dailyCountInfo;
// });
// let exchangeArr = ref([
//   { name: "OKX" },
//   { name: "Toobit" },
//   { name: "BingX" },
//   { name: "Deepcoin" },
// ]);
const closeModal = () => {
  store.commit("admin/changeModalState", false);
};
const createdFn = async () => {
  // for (const obj of exchangeArr.value) {
  //   let exchangeId;
  //   switch (obj.name) {
  //     case "OKX":
  //       exchangeId = 1;
  //       break;
  //     case "Toobit":
  //       exchangeId = 3;
  //       break;
  //     case "BingX":
  //       exchangeId = 2;
  //       break;
  //     case "Deepcoin":
  //       exchangeId = 4;
  //       break;
  //   }
  //   let reqInfo = {
  //     exchange: exchangeId ?? "",
  //     start_date: "",
  //     end_date: "",
  //   };
  //   store.commit("closing/setDailyInfo", reqInfo);
  //   await store.dispatch("closing/getProfitMonth", exchangeId);
  //   await store.dispatch("closing/getWeekExchange", exchangeId);
  //   await store.dispatch("closing/getDailyProfit");
  //   obj.monthly_profit = monthCountInfo.value?.monthly_profit ?? "-";
  //   obj.total_profit = monthCountInfo.value?.total_accumulated_profit ?? "-";
  //   obj.weekly_profit = weekCountInfo.value?.weekly_profit ?? "-";
  //   obj.day_profit = dailyCountInfo.value?.day_profit ?? "-";
  // }
};
createdFn();
</script>

<style lang="scss" scoped></style>
