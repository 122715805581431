import api from "@/api/apiManage";

export default {
  namespaced: true,
  state() {
    return {
      isLoading: false,
      reqLogInfo: {},
      reqMemberInfo: {
        exchange: 0,
        size: 5,
      },
      page: 1,
      //res
      dashboardInfo: {},
      totalPages: 0,
      memberList: [],
      logList : [],
    };
  },
  mutations: {
    setPage(state, str) {
      if (str == "prev") {
        state.page--;
      } else if (str == "next") {
        state.page++;
      } else {
        state.page = 1;
      }
    },
    setReqLogInfo(state, payload) {
      state.reqLogInfo = {
        size: 10,
        status: payload.status,
        from_data: payload.from_data,
        to_data: payload.to_data,
        retri_id: payload.retri_id,
        exchange: payload.exchange,
        uid : payload.uid,
      };
    },
  },
  actions: {
    async getDashBoard(context) {
      try {
        let response = await api.getDashBoardApi();
        context.state.dashboardInfo = response.results;
      } catch (error) {
        return;
      }
    },
    async getTotalMember(context) {
      try {
        let response = await api.getTotalMemberApi(
          context.state.reqMemberInfo,
          context.state.page
        );
        context.state.memberList = response.results;
        context.state.totalPages = response.totalPages;
      } catch (error) {
        return;
      }
    },
    async getNewMember(context) {
      try {
        let response = await api.getNewMemberApi(
          context.state.reqMemberInfo,
          context.state.page
        );
        context.state.memberList = response.results;
        context.state.totalPages = response.totalPages;
      } catch (error) {
        return;
      }
    },
    async getUidLog(context) {
      try {
        context.state.totalPages = 1;
        context.state.isLoading = true;
        let response = await api.getUidLogApi(
          context.state.reqLogInfo,
          context.state.page
        );
        context.state.logList = response.results;
        context.state.totalPages = response.totalPages;
        context.state.isLoading = false;
      } catch (error) {
        return;
      }
    },
  },
};
