import { API } from "./apiAuth";
let whileFetching = false;
let abortController;

// Toobit excel file 등록 api
const postToobitApi = async (formData) => {
  try {
    const response = await API.post("Toobit-income", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    if (error.response.status === 500) {
      return error.response;
    }
    return;
  }
};
// excel 등록 조회 캘린더 api
const getCalenderApi = async (info) => {
  try {
    const response = await API.get(
      `manage/manage-check-date?fromdate=${info.fromdate}&exchange=${info.exchange}`
    );
    return response.data;
  } catch (error) {
    return;
  }
};

// 유저 페이백 내역 조회 api
const getPaybackLogApi = async (info, page) => {
  try {
    if (whileFetching) abortController.abort();
    abortController = new AbortController();
    const signal = abortController.signal;
    whileFetching = true;
    const response = await API.get(
      `admin-payment-report?page=${page}&per_page=${info.per_page}&exchange=${info.exchange}&start_date=${info.start_date}&end_date=${info.end_date}&retri_id=${info.retri_id}&uid=${info.uid}`,
      { signal }
    );
    whileFetching = false;
    return response.data;
  } catch (error) {
    return;
  }
};
//리트리 아이디별 거래소 총합 수익 캘린더 api
const getExchangeTotalApi = async (retri_id) => {
  try {
    const response = await API.get(
      `total_profit?retri_id=${retri_id}`
    );
    return response.data;
  } catch (error) {
    return;
  }
};

export default {
  postToobitApi,
  getCalenderApi,
  getPaybackLogApi,
  getExchangeTotalApi,
};
