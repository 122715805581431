<template>
  <div class="content_wrapper">
    <div class="pl-10 pr-10 mb-20">
      <div class="dash_title">
        <h1>DashBoard</h1>
        <span>[REPORT] {{ reportDate }}</span>
      </div>
      <div class="dash_board_wrap">
        <div class="col-md-6 col-12 mb-20 pr-10 pl-10">
          <div class="dash_box" @click="modalOpen(4)">
            <i class="ti ti-arrow-autofit-left ti-xl"></i>
            <div class="dash_text_area">
              <h3>Total profit before settlement</h3>
              <p class="ml-10">
                {{ formatNum(dashboardInfo.total_befor_profit, 4) }}$
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12 mb-20 pr-10 pl-10">
          <div class="dash_box" @click="modalOpen(3)">
            <i class="ti ti-coins"></i>
            <div class="dash_text_area">
              <h3>Total Profit</h3>
              <p class="ml-10">
                {{ formatNum(dashboardInfo.total_profit, 4) }}$
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-12 mb-20 pr-10 pl-10 diff">
          <div class="dash_box" @click="modalOpen(4)">
            <i class="ti ti-arrow-autofit-left ti-xl"></i>
            <div class="dash_text_area">
              <h3>Monthly profit before settlement</h3>
              <p class="ml-10">
                {{ formatNum(dashboardInfo.month_befor_profit, 4) }}$
              </p>
            </div>
          </div>
        </div>

        <div class="col-md-4 col-12 mb-20 pr-10 pl-10 diff">
          <div class="dash_box" @click="modalOpen(4)">
            <i class="ti ti-arrow-autofit-left ti-xl"></i>
            <div class="dash_text_area">
              <h3>Weekly profit before settlement</h3>
              <p class="ml-10">
                {{ formatNum(dashboardInfo.week_befor_profit, 4) }}$
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-12 mb-20 pr-10 pl-10 diff">
          <div class="dash_box" @click="modalOpen(4)">
            <i class="ti ti-arrow-autofit-left ti-xl"></i>
            <div class="dash_text_area">
              <h3>Daily profit before settlement</h3>
              <p class="ml-10">
                {{ formatNum(dashboardInfo.day_befor_profit, 4) }}$
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-12 mb-20 pr-10 pl-10 diff">
          <div class="dash_box" @click="modalOpen(3)">
            <i class="ti ti-currency-dollar ti-xl"></i>
            <div class="dash_text_area">
              <h3>This Month Profit</h3>
              <p class="ml-10">
                {{ formatNum(dashboardInfo.month_profit, 4) }}$
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-12 mb-20 pr-10 pl-10 diff">
          <div class="dash_box" @click="modalOpen(3)">
            <i class="ti ti-currency-dollar ti-xl"></i>
            <div class="dash_text_area">
              <h3>This Week Profit</h3>
              <p class="ml-10">
                {{ formatNum(dashboardInfo.week_profit, 4) }}$
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-12 mb-20 pr-10 pl-10 diff">
          <div class="dash_box" @click="modalOpen(3)">
            <i class="ti ti-currency-dollar ti-xl"></i>
            <div class="dash_text_area">
              <h3>This Daily Profit</h3>
              <p class="ml-10">{{ formatNum(dashboardInfo.day_profit, 4) }}$</p>
            </div>
          </div>
        </div>
        <div class="col-md-5 col-12 mb-20 pr-10 pl-10">
          <div class="dash_box" @click="modalOpen(1)">
            <i class="ti ti-user"></i>
            <div class="dash_text_area">
              <h3>Total Member</h3>
              <p class="ml-10">{{ dashboardInfo.total_count }}</p>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-12 mb-20 pr-10 pl-10">
          <div class="dash_box" @click="modalOpen(2)">
            <i class="ti ti-user-plus"></i>
            <div class="dash_text_area">
              <h3>New Member</h3>
              <p class="ml-10">{{ dashboardInfo.total_today }}</p>
            </div>
          </div>
        </div>
      </div>
      <p class="col-12 mt-20 pr-10 pl-10">
        ※ Toobit 은 2일 전 데이터 / DeepCoin, OKX, BingX 는 1일 전 데이터입니다.
      </p>
      <p class="col-12 mt-20 mb-10 pr-10 pl-10">&nbsp; 해당 데이터는 정산 후 데이터입니다.</p>
      <div class="dash-chart">
        <TheBar v-if="!isLoading" :exchangeArr="exchangeArr"></TheBar>
      </div>
    </div>
  </div>
  <ModalList
    :status="status"
    v-if="modalState && (status == 1 || status == 2)"
  />
  <ModalProfit
    :status="status"
    :exchangeArr="exchangeArr"
    :dashboardInfo="dashboardInfo"
    v-if="modalState && (status == 3 || status == 4)"
  />
</template>
<script setup>
import { autoLeftPad, formatNum, numFloor } from "@/utils/common";
import ModalList from "./modal/ModalList.vue";
import ModalProfit from "./modal/ModalProfit.vue";
import TheBar from "./public/TheBar.vue";
import { computed, ref } from "vue";
import { useStore } from "vuex";

const store = useStore();
const dashboardInfo = computed(() => {
  return store.state.manage.dashboardInfo;
});
const monthCountInfo = computed(() => {
  return store.state.closing.monthCountInfo;
});
const weekCountInfo = computed(() => {
  return store.state.closing.weekCountInfo;
});
const dailyCountInfo = computed(() => {
  return store.state.closing.dailyCountInfo;
});
let exchangeArr = ref([
  { name: "OKX" },
  { name: "Toobit" },
  { name: "BingX" },
  { name: "Deepcoin" },
]);
let info = {
  exchange: 0,
  start_date: "",
  end_date: "",
};
const modalState = computed(() => {
  return store.state.admin.modalState;
});
let isLoading = ref(false);
let status = ref(null);
const date = new Date();
const reportDate = `${date.getFullYear()}
.${autoLeftPad(date.getMonth() + 1, 2)}
.${autoLeftPad(date.getDate(), 2)} 
${date.getHours()}:00`;

const modalOpen = (state) => {
  status.value = state;
  store.commit("admin/changeModalState", true);
};
const createdFn = async () => {
  isLoading.value = true;
  await store.dispatch("manage/getDashBoard");
  for (const obj of exchangeArr.value) {
    await fetchExchangeData(obj);
  }
  isLoading.value = false;
};
const fetchExchangeData = async (exchange) => {
  let exchangeId;
  switch (exchange.name) {
    case "OKX":
      exchangeId = 1;
      break;
    case "Toobit":
      exchangeId = 3;
      break;
    case "BingX":
      exchangeId = 2;
      break;
    case "Deepcoin":
      exchangeId = 4;
      break;
    default:
      return;
  }
  info.exchange = exchangeId;
  await store.dispatch("closing/getProfitMonth", exchangeId);
  await store.dispatch("closing/getWeekExchange", exchangeId);
  store.commit("closing/setDailyInfo", info);
  await store.dispatch("closing/getDailyProfit");
  exchange.monthly_profit = numFloor(monthCountInfo.value.monthly_profit,4) ?? 0;
  exchange.total_profit = numFloor(monthCountInfo.value.total_accumulated_profit,4) ?? 0;
  exchange.weekly_profit = numFloor(weekCountInfo.value.weekly_profit,4) ?? 0;
  exchange.daily_profit = numFloor(dailyCountInfo.value.day_profit,4) ?? 0;
  exchange.before_monthly_profit =
  numFloor(monthCountInfo.value.before_monthly_profit,4) ?? 0;
  exchange.before_total_profit =
  numFloor(monthCountInfo.value.before_total_profit,4) ?? 0;
  exchange.before_weekly_profit =
  numFloor(weekCountInfo.value.before_weekly_profit,4) ?? 0;
  exchange.before_daily_profit = numFloor(dailyCountInfo.value.before_day_profit,4) ?? 0;
};
createdFn();
</script>
