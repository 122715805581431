<template>
  <div class="tabMenu_inner border-0 mt-0">
    <ul class="tab_link_box ctgy02">
      <li :class="{ active: exchange === 0 }" @click="changeTab(0)">ALL</li>
      <li :class="{ active: exchange === 1 }" @click="changeTab(1)">OKX</li>
      <li :class="{ active: exchange === 3 }" @click="changeTab(3)">Toobit</li>
      <li :class="{ active: exchange === 2 }" @click="changeTab(2)">BingX</li>
      <li :class="{ active: exchange === 4 }" @click="changeTab(4)">
        DeepCoin
      </li>
    </ul>
  </div>
  <div class="total_info diff mb-50">
    <div>
      <p class="fs-15">정산 전 총 수익금</p>
      <p class="fs-25 text_blue">
        {{ formatNum(weekCountInfo.before_total_profit, 4) }}$
      </p>
    </div>
    <div>
      <p class="fs-15">정산 후 총 수익금</p>
      <p class="fs-25 text_plus">
        {{ formatNum(weekCountInfo.total_accumulated_profit, 4) }}$
      </p>
    </div>
    <div>
      <p class="fs-15">정산 전 금주 수익금</p>
      <p class="fs-25 text_blue">
        {{ formatNum(weekCountInfo.before_weekly_profit, 4) }}$
      </p>
    </div>
    <div>
      <p class="fs-15">정산 후 금주 수익금</p>
      <p class="fs-25 text_plus">
        {{ formatNum(weekCountInfo.weekly_profit, 4) }}$
      </p>
    </div>
  </div>
  <div class="card mb-20">
    <div class="card_header">
      <h5 class="card_title"><i class="ti ti-files mr-5"></i>주간결산</h5>
      <button class="btn-primary pd-10 mr-20" @click="sendWeekIncome()">
        주간 결산 보내기
      </button>
    </div>
    <div class="form_inner">
      <div class="col-12 mb-15 search_group">
        <h5 class="item_name">RETRI_IDX</h5>
        <input
          type="number"
          v-model="info.retri_id"
          class="form_control display-inline wp-36"
          placeholder="Search"
          @keyup.enter="infoSearch"
        />
      </div>
      <div class="col-12 mb-15 search_group">
        <h5 class="item_name">UID</h5>
        <input
          type="text"
          v-model="info.uid"
          class="form_control display-inline wp-36"
          placeholder="UID search"
          @keyup.enter="infoSearch"
        />
      </div>
      <div class="col-12 mb-15 search_group">
        <h5 class="item_name">거래소</h5>
        <select
          class="form-select display-inline wp-15 mr-5"
          data-allow-clear="true"
          v-model="info.exchange"
        >
          <option value="">All</option>
          <option value="okx">OKX</option>
          <option value="toobit">Toobit</option>
          <option value="bingx">BingX</option>
          <option value="deepcoin">DeepCoin</option>
        </select>
      </div>
      <div class="col-12 mb-15 search_group">
        <h5 class="item_name">주간 검색</h5>
        <input
          type="week"
          class="form_control wp-20 mr-5 display-inline"
          v-model="info.date"
        />
      </div>
      <div class="filter-btn">
        <button type="button" class="btn btn-primary mlr-5" @click="infoSearch">
          검색
        </button>
        <button
          @click="infoClear"
          type="button"
          class="btn btn-secondary mlr-5"
        >
          클리어
        </button>
      </div>
    </div>
  </div>
  <!--  -->
  <div class="col-12 mb-20">
    <div class="card">
      <div class="table-responsive text-nowrap dataTables_wrapper">
        <table class="table">
          <thead class="table-light">
            <tr>
              <th>RETRI_IDX</th>
              <th>거래소</th>
              <th>UID</th>
              <th>수익금</th>
              <th>발생시간</th>
            </tr>
          </thead>
          <tbody v-if="!isLoading">
            <tr v-if="monthProfitList.length == 0">
              <td colspan="7">데이터 내역이 없습니다.</td>
            </tr>
            <tr v-for="(data, i) in monthProfitList" :key="i">
              <td>{{ data.retri_id }}</td>
              <td>{{ data.exchange }}</td>
              <td>{{ data.uid }}</td>
              <td>{{ formatNum(data.profit, 4) }}</td>
              <td>
                {{ data.datetime?.replace("T", " ").slice(0, 16) }}
              </td>
            </tr>
          </tbody>
          <tbody v-if="isLoading">
            <td colspan="8" class="list_loading">
              <div class="loading_spinner">
                <div class="loading_circle"></div>
              </div>
            </td>
          </tbody>
        </table>
        <ThePaging
          v-if="totalPages > 1"
          :page="page"
          :changePage="changePage"
          :totalPages="totalPages"
        />
      </div>
    </div>
  </div>
  <ModalMsg
    v-if="modalState"
    :msg="fetchStatus == 200 ? '성공적으로 보냈습니다.' : '실패하였습니다. 다시 시도해주세요.'"
  />
</template>
<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import ThePaging from "@/components/public/ThePaging.vue";
import { formatNum, getItemWithExpireTime } from "@/utils/common";
import ModalMsg from "@/components/modal/ModalMsg.vue";
const store = useStore();
const monthProfitList = computed(() => {
  return store.state.closing.monthProfitList;
});
const weekCountInfo = computed(() => {
  return store.state.closing.weekCountInfo;
});
const page = computed(() => {
  return store.state.closing.page;
});
const totalPages = computed(() => {
  return store.state.closing.totalPages;
});
const isLoading = computed(() => {
  return store.state.closing.isLoading;
});
const fetchStatus = computed(() => {
  return store.state.closing.fetchStatus;
});
const modalState = computed(() => {
  return store.state.admin.modalState;
});
const date = new Date();
const todayWeek = getWeekFromISODate(date);
let exchange = ref(0);
let info = {
  type: 0,
  date: todayWeek,
  retri_id: "",
  exchange: "",
  uid: "",
};
const initInfo = {
  type: 0,
  date: "",
  retri_id: "",
  exchange: "",
  uid: "",
};
function getWeekFromISODate(dt) {
  dt.setHours(0, 0, 0, 0);
  dt.setDate(dt.getDate() + 3 - ((dt.getDay() + 6) % 7));
  const week1 = new Date(dt.getFullYear(), 0, 4);
  const weekNumber =
    1 +
    Math.round(
      ((dt.getTime() - week1.getTime()) / 86400000 -
        3 +
        ((week1.getDay() + 6) % 7)) /
        7
    );
  return `${dt.getFullYear()}-W${weekNumber}`;
}
const infoSearch = async () => {
  let reqInfo = {
    type: 0,
    date: info.date.replace("-W", "") ?? "",
    retri_id: info.retri_id ?? "",
    exchange: info.exchange ?? "",
    uid: info.uid ?? "",
  };
  store.commit("closing/setPage", 1);
  store.commit("closing/setMonthInfo", reqInfo);
  await store.dispatch("closing/getUserProfit");
};
const infoClear = async () => {
  info = {
    type: 0,
    date: "",
    retri_id: "",
    exchange: "",
    uid: "",
  };
  store.commit("closing/setMonthInfo", info);
  store.commit("closing/setPage", 1);
  await store.dispatch("closing/getUserProfit");
};
const sendWeekIncome = async () => {
  const admin = getItemWithExpireTime("userInfoObj")?.pk;
  await store.dispatch("closing/getWeekIncome", admin);
  store.commit("admin/changeModalState", true);
};
const changePage = async (str) => {
  store.commit("closing/setPage", str);
  await store.dispatch("closing/getUserProfit");
};
const changeTab = async (state) => {
  exchange.value = state;
  let exchangeName;
  switch (exchange.value) {
    case 0:
      exchangeName = "";
      break;
    case 1:
      exchangeName = "okx";
      break;
    case 2:
      exchangeName = "bingx";
      break;
    case 3:
      exchangeName = "toobit";
      break;
    case 4:
      exchangeName = "deepcoin";
      break;
  }
  let reqInfo = {
    type: 0,
    date: "",
    retri_id: "",
    exchange: exchangeName ?? "",
    uid: "",
  };
  store.commit("closing/setPage", 1);
  store.commit("closing/setMonthInfo", reqInfo);
  await store.dispatch("closing/getWeekExchange", exchange.value);
  await store.dispatch("closing/getUserProfit");
};
const createFn = async () => {
  store.commit("closing/setPage", 1);
  store.commit("closing/setMonthInfo", initInfo);
  await store.dispatch("closing/getWeekExchange", 0);
  await store.dispatch("closing/getUserProfit");
};
createFn();
</script>
