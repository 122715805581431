<template>
  <div class="dash-chart">
    <div>
      <apexchart
        width="100%"
        height="400"
        type="donut"
        :options="totalOptions"
        :series="totalSeries"
      ></apexchart>
      <p>총 수익금</p>
    </div>
    <div>
      <apexchart
        width="100%"
        height="400"
        type="bar"
        :options="options"
        :series="series"
      ></apexchart>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  exchangeArr: { type: Array },
});
const options = {
  chart: {
    id: "chart",
    stacked: true,
  },
  legend: {
    position: "bottom",
  },
  xaxis: {
    categories: ["금일 수익금", "금주 수익금", "금월 수익금"],
  },
  tooltip: {
    style: { width: "200px", fontSize: "18px" },
  },
};
const series = [
  {
    name: "OKX",
    data: [
      props.exchangeArr[0].daily_profit,
      props.exchangeArr[0].weekly_profit,
      props.exchangeArr[0].monthly_profit,
    ],
  },
  {
    name: "Toobit",
    data: [
      props.exchangeArr[1].daily_profit,
      props.exchangeArr[1].weekly_profit,
      props.exchangeArr[1].monthly_profit,
    ],
  },
  {
    name: "BingX",
    data: [
      props.exchangeArr[2].daily_profit,
      props.exchangeArr[2].weekly_profit,
      props.exchangeArr[2].monthly_profit,
    ],
  },
  {
    name: "Deepcoin",
    data: [
      props.exchangeArr[3].daily_profit,
      props.exchangeArr[3].weekly_profit,
      props.exchangeArr[3].monthly_profit,
    ],
  },
];
const totalOptions = {
  chart: {
    id: "vuechart",
  },
  legend: {
    show: false,
  },
  tooltip: {
    style: {fontSize: "18px" },
  },
  labels: ["OKX", "Toobit", "BingX", "Deepcoin"],
  plotOptions: {
    pie: {
      dataLabels: {
        offset: -5,
      },
    },
  },
};
const totalSeries = [
  props.exchangeArr[0].total_profit,
  props.exchangeArr[1].total_profit,
  props.exchangeArr[2].total_profit,
  props.exchangeArr[3].total_profit,
];
</script>

<style lang="scss" scoped></style>
